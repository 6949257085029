import React from "react";
import PropTypes from "prop-types";
import { Button } from "@components/button";
import { airbrakeClient } from "@utils/airbrake/browser";
import { faro } from "@grafana/faro-web-sdk";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  // not looking to use the `error` value here, just capturing that an error was thrown
  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Reporting error to logging service
    airbrakeClient.notify({ error, params: { errorInfo } });
    faro.api.pushError(error);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div>
          <h2 >The UK is shocked! Read more here -&gt;</h2>
          <Button onClick={() => this.setState({ hasError: false })}>
            Try again?
          </Button>
          or
          <Button href="/" className="btn-primary">
            Go to home page
          </Button>
        </div>
      );
    }

    // Return children components in case of no error
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
